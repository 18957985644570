'use strict';

import EditBasketProduct from 'org/components/common/EditBasketProduct';

export default class EditBasketProductAPAC extends EditBasketProduct {
    init() {
        super.init();
    }

    onUpdateCartProduct() {
        var cardTileComponent = $('[data-target-id="' + this.targetID + '"]').closest('.card').data().cmpInstance;
        // eslint-disable-next-line no-restricted-globals
        var eGiftCardUpdateForm = $(event.currentTarget).find('form.e-giftcard');
        var eGiftCardUpdateError = eGiftCardUpdateForm.length ? !(eGiftCardUpdateForm[0].checkValidity()) || $(eGiftCardUpdateForm).find('input.is-invalid').length : '';
        if (!eGiftCardUpdateError) {
            cardTileComponent.onUpdateCartProduct.apply(cardTileComponent, arguments);
        }
    }
}
