'use strict';

// eslint-disable-next-line valid-jsdoc
/**
 * Renders a modal window that will open a email pop up for first time users
 */
function getCookie(name) {
    // eslint-disable-next-line one-var
    var value = '; ' + document.cookie,
        parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return '';
}

// eslint-disable-next-line require-jsdoc
function createCookie(name, value) {
    document.cookie = name + '=' + value + '; path=/';
}

/**
 * Displays the consent banner on the page and adds a class to the body to indicate it is shown.
 * This function checks if the consent banner exists and is hidden, then shows it and applies the 'm-consent-show' class to the body element.
 */
function showConsentBanner() {
    var $consentBanner = $('.l-consent_banner');
    if ($consentBanner && $consentBanner.length > 0) {
        $consentBanner.show();
        $('body').addClass('m-consent-show');
    }
}

/**
 * Hides the consent banner on the page and removes the related class from the body.
 * This function checks if the consent banner exists and is currently visible, then hides it and removes the 'm-consent-show' class from the body element.
 */
function hideConsentBanner() {
    var $consentBanner = $('.l-consent_banner');
    if ($consentBanner && $consentBanner.length > 0) {
        $consentBanner.hide();
        $('body').removeClass('m-consent-show');
    }
}

module.exports = function () {
    $('#teconsent').add('#teconsent a').add('.l-consent_banner .close').on('click', function () {
        createCookie('consentCookie', 'first');
        hideConsentBanner();
    });
    if (getCookie('consentCookie') === '' || getCookie('consentCookie') === undefined || getCookie('consentCookie') == null) {
        createCookie('consentCookie', 'first');
        showConsentBanner();
    } else {
        hideConsentBanner();
    }
    // codes for privacy policy banner
    if ($('.l-privacy_banner:visible').length) {
        $('body').addClass('m-privacy_banner-show');
    }
    $('.l-privacy_banner .close').on('click', function () {
        $('.l-privacy_banner').hide();
        $('body').removeClass('m-privacy_banner-show');
    });
};
