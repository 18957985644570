/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */

'use strict';

import ProductEMEA from 'falcon/components/product/ProductDetailEMEA';
var sanitizer = require('../../utils/sanitizeString');
export default class Product extends ProductEMEA {
    init() {
        super.init();
        this.eventDelegate('click', '.js-giftcard-amount-select', this.onGiftCardAmountSelect.bind(this));
        this.$eGiftCardMsg = $('body').find('textarea#gc-message');
        $(this.$eGiftCardMsg).val(this.$eGiftCardMsg.attr('value') || '');
        $(this.$eGiftCardAmount).val(parseFloat(this.$eGiftCardAmount.attr('value')) || '');
        $('#gc-amount').css({
            'padding-left': $('.dollarSymbol').width() + 16
        });
    }

    initEvents() {
        super.initEvents();
        this.$eGiftCardMsg = $('body').find('textarea#gc-message');
        if (this.$eGiftCardMsg.length) {
            // as text area does not allow to add pattern validation of input,
            // we can replace the invalid chars the same way US site does
            this.$eGiftCardMsg.on('input', function () {
                this.value = sanitizer.sanitizeString(this.value);
            });
            this.eventDelegate('change', this.$eGiftCardMsg, this.onMessageChange.bind(this));
        }
    }

    onMessageChange(event) {
        var messageFieldValue = $(event.target).val();
        var messageTextArea = $(event.target);
        var customRegexExp = new RegExp($('[name$="xssRegex"]').val(), '');
        var addToBagButton = $('.b-egiftcard-button');
        if (messageFieldValue && customRegexExp.test(messageFieldValue)) {
            messageTextArea.addClass('is-invalid');
            messageTextArea.parent().find('.invalid-feedback').html($('[name$="xssRegex"]').attr('message'));
            messageTextArea.parent('.b-input_row').addClass('error-field');
            if (addToBagButton.hasClass('js-egiftcard-update')) {
                addToBagButton.attr('disabled', 'disabled');
            }
        } else {
            messageTextArea.parent('.b-input_row').removeClass('error-field');
            messageTextArea.removeClass('is-invalid');
            messageTextArea.parent().find('.invalid-feedback').html('');
            addToBagButton.removeAttr('disabled');
        }
    }

    deselectAmountSwatch() {
        this.$selectedAmount = $('li.js-giftcard-amount-select a.selected');
        if (this.$selectedAmount.length >= 1) {
            $(this.$selectedAmount).each(function () {
                $(this).removeClass('selected');
            });
        }
    }

    checkAmountCheck(event) {
        var dateAndAmount = $('.js-giftcard-amount-date');
        var amountField = $('.js-giftcard-amount');
        var addToBagButton = $('.b-egiftcard-button');
        var amountFieldVal = amountField.val();
        if (!amountFieldVal) {
            amountField.addClass('is-invalid');
            amountField.parent('.b-input_row').addClass('error-field');
            amountField.parents('.b-product_attrs-item').addClass('has-error');
            if (addToBagButton.hasClass('js-egiftcard-update')) {
                addToBagButton.attr('disabled', 'disabled');
            }
            this.deselectAmountSwatch();
            return;
        }
        var minimumAmount = parseInt(amountField.data('min-amount'), 10);
        var maxAmount = parseInt(amountField.data('max-amount'), 10);
        var regex = /^[0-9]+(\.[0-9]{1,2})?$/;
        this.deselectAmountSwatch();

        var swatchBtn = $('.js-giftcard-amount-select');
        var selectedElement = null;
        if (!event.isTrigger) {
            swatchBtn.each(function () {
                if ($(this).data('value') === parseInt(amountFieldVal, 10)) {
                    selectedElement = $(this).find('a');
                    selectedElement.addClass('selected');
                }
            });
        }

        if (isNaN(amountFieldVal)) {
            amountField.addClass('is-invalid');
            amountField.parent().find('.invalid-gcamount-feedback').html(amountField.data('pattern-mismatch'));
            amountField.parents('.b-product_attrs-item').addClass('has-error');
            amountField.parent('.b-input_row').addClass('error-field');
            if (addToBagButton.hasClass('js-egiftcard-update')) {
                addToBagButton.attr('disabled', 'disabled');
            }
            return;
        }
        if (amountFieldVal.match(regex) === null) {
            event.preventDefault();
            amountField.addClass('is-invalid');
            amountField.parent('.b-input_row').addClass('error-field');
            amountField.parent().find('.invalid-gcamount-feedback').html(amountField.data('valid-error'));
            amountField.parents('.b-product_attrs-item').addClass('has-error');
            if (addToBagButton.hasClass('js-egiftcard-update')) {
                addToBagButton.attr('disabled', 'disabled');
            }
        } else if (amountFieldVal % 5 !== 0 || !((amountFieldVal >= minimumAmount) && (amountFieldVal <= maxAmount))) {
            event.preventDefault();
            amountField.addClass('is-invalid');
            amountField.parent('.b-input_row').addClass('error-field');
            amountField.parent().find('.invalid-gcamount-feedback').html(amountField.data('valid-error'));
            amountField.parents('.b-product_attrs-item').addClass('has-error');
            amountField[0].setCustomValidity(amountField.data('valid-error'));
            if (addToBagButton.hasClass('js-egiftcard-update')) {
                addToBagButton.attr('disabled', 'disabled');
            }
        } else if (amountFieldVal % 5 === 0 || (amountFieldVal >= minimumAmount && amountFieldVal <= maxAmount)) {
            amountField[0].setCustomValidity('');
            amountField.removeClass('is-invalid');
            // amountField.parent().find('.invalid-feedback').html('');
            amountField.parents('.b-product_attrs-item').removeClass('has-error');
            amountField.parent('.b-input_row').removeClass('error-field');
            if (!dateAndAmount.hasClass('is-invalid')) {
                addToBagButton.removeAttr('disabled');
            }
        } else {
            event.preventDefault();
            amountField.addClass('is-invalid');
            amountField.parent().find('.invalid-gcamount-feedback').html(amountField.data('valid-error'));
            amountField.parents('.b-product_attrs-item').addClass('has-error');
            amountField.parent('.b-input_row').addClass('error-field');
            if (addToBagButton.hasClass('js-egiftcard-update')) {
                addToBagButton.attr('disabled', 'disabled');
            }
        }
    }

    checkDateCheck(event) {
        event.preventDefault();
        var dateField = $('.js-deliverydate');
        var dateAndAmount = $('.js-giftcard-amount-date');
        var addToBagButton = $('.b-egiftcard-button');
        var date = new Date($('.js-deliverydate').val());
        var now = new Date();
        var minDate = new Date($(dateField).attr('min'));
        var maxDate = new Date(now.setDate(now.getDate() + parseInt($(dateField).data('delivery-within'), 10)));

        if (maxDate < date) {
            dateField.addClass('is-invalid');
            dateField.parent().find('.invalid-feedback').html(dateField.data('range-error'));
            dateField.parent('.b-input_row').addClass('error-field');
            if (addToBagButton.hasClass('js-egiftcard-update')) {
                addToBagButton.attr('disabled', 'disabled');
            }
        } else if (date < minDate) {
            dateField.addClass('is-invalid');
            dateField.parent().find('.invalid-feedback').html(dateField.data('valid-error'));
            dateField.parent('.b-input_row').addClass('error-field');
            if (addToBagButton.hasClass('js-egiftcard-update')) {
                addToBagButton.attr('disabled', 'disabled');
            }
        } else {
            dateField.removeClass('is-invalid');
            dateField.parent().find('.invalid-feedback').html('');
            dateField.parent('.b-input_row').removeClass('error-field');
            if (!dateAndAmount.hasClass('is-invalid')) {
                addToBagButton.removeAttr('disabled');
            }
        }
    }

    onGiftCardAmountSelect(event) {
        event.preventDefault();
        var swatchButton = event.currentTarget;
        $('#gc-amount').prop('value', $(swatchButton).data('value'));
        $('.js-giftcard-amount').trigger('change');
        $(swatchButton).find('a').addClass('selected');
    }
}
