/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */

'use strict';

import ProductAPAC from './Product';
import { isEmpty } from 'lodash';

var scrollAnimate = require('org/components/scrollAnimate');
var savedItem = true;
var productId;
var isVisible = require('org/components/isVisible');
var freeShippingBar = require('org/components/cart/FreeShippingBar');


export default class ProductDetail extends ProductAPAC {
    init() {
        super.init();
        $(window).on('load', this.checkEarlyAccessProductInURL);
    }

    /**
    * Function to select variation attributes values from subsequent params appended in URL. After selection of the attribures removing the params from URL.
    */
    checkEarlyAccessProductInURL() {
        if ($('.js-login').length === 0 && ($('.pdp-mobile-auth').length === 0 || $('.pdp-mobile-auth').attr('data-mobileauth-enabled') !== 'true' || $('.pdp-mobile-auth').attr('data-mobileauth-pending') !== 'true')) { // check if mobile authentication is pending
            var urlParams = new URLSearchParams(window.location.search);
            var triggerATC = urlParams.has('triggerATC');
            if (triggerATC && $('.ua-early-access').length) {
                var $uaEarlyAccessBlock = $('.ua-early-access');
                $('.b-product_attrs-item.variation_attr').each(function () {
                    var varAttr = $(this).attr('data-attr');
                    if (varAttr === 'color') {
                        return;
                    }
                    var varAttrVal = $uaEarlyAccessBlock.data(varAttr);
                    if (!isEmpty(varAttrVal)) {
                        var valLink = $(this).find('.b-product_attribute ul li a[data-attr-value="' + varAttrVal + '"]');
                        if ((!isEmpty(valLink)) && !valLink.hasClass('selected')) {
                            valLink.addClass('selected');
                        }
                    }
                    if (urlParams.has('eaQty')) {
                        var quantityValue = urlParams.get('eaQty');
                        $('.js-quantity-select').val(quantityValue);
                    }
                });
                if (urlParams.has('earlyAccessPid')) {
                    var eaPidValue = urlParams.get('earlyAccessPid');
                    $('.l-pdp.product-detail').data('pid', eaPidValue);
                }
                $('.b-product_actions-inner .js-add-to-cart').trigger('click');
                urlParams.delete('earlyAccessPid');
                urlParams.delete('triggerATC');
                urlParams.delete('size');
                urlParams.delete('eaQty');
                var hash = '';
                if (window.location.href.indexOf('#') !== -1) {
                    hash = '#' + window.location.href.split('#')[1];
                }
                var url = window.location.pathname + '?' + urlParams.toString() + hash;
                history.replaceState({}, '', url);
            }
        }
    }

    onupdateAddToCartModal(e, responseData) {
        var url = $('.added-tocart-confirmation-modal-url').val();
        var form = {};
        form = {
            pid: $('.l-pdp.product-detail').data('pid'),
            qty: $('.js-quantity-select').val()
        };
        var lineItem = responseData.cart.items.find(item => responseData.pliUUID === item.UUID);
        form.isPersonalizationEligible = lineItem.isPersonalizationEligible;
        if (lineItem.isPersonalizationEligible) {
            form.personalizationDetail = lineItem.personalizationDetail;
            form.optionalItemPrice = lineItem.priceTotal.optionalItemPrice;
        }
        if ($('.js-cmp-productDetail form.e-giftcard').length) {
            var amount = $('.js-cmp-productDetail form.e-giftcard').find('.js-giftcard-amount').val();
            form.gcAmount = parseFloat(amount).toFixed(2);
        }
        if ($('.b-size_pdp .b-select-size-outer ul li a.selected').length > 0 && $('.b-size_pdp .b-select-size-outer ul li a.selected').attr('data-size-attr')) {
            form.gcAmount = $('.b-size_pdp .b-select-size-outer ul li a.selected').attr('data-size-attr');
        }
        $.spinner().start();
        if (url) {
            $.ajax({
                url: url,
                type: 'get',
                data: form,
                success: function (response) {
                    $('#cartConfirmationModal .js-product-detailsConfirmation').html(response);
                    $('#cartConfirmationModal').modal('show');
                    if ($('.b-add-to-cart-confirmation-modal-container').data('giftcard') === true) {
                        $('.b-cart-added-confirmation-modal').find('.b-cart-content-recommendation').hide();
                        $('#cartConfirmationModal .js-product-detailsConfirmation').addClass('eGiftcard-Added');
                    }
                    $('.js-confirmation-modal-recommendation-tiles').removeClass('hide');
                    $('.b-cart-added-confirmation-modal').find('.product-listing').trigger('mainCarousel:update');
                    $.spinner().stop();
                    setTimeout(function () {
                        $('body').trigger('components:init');
                    }, 500);
                },
                error: function (err) {
                    console.log(err);
                }
            });
        }
    }

    displayEarlyAccessErrorModal(content) {
        var $modal = $('#nonEACustomerModal');
        $modal.find('.early-access-modal-container').html(content);
        $('#nonEACustomerModal').modal('show');
    }

    onAddToCart(event) {
        var addToCartUrl;
        var pidsObj;
        var setPids;
        var requiredSelections = ['color', 'length', 'size', 'amount'];

        if ($('.b-add_to_bag .js-add-to-cart', this.$el).attr('exclusive-oos') === 'true') {
            return;
        }

        var $target;
        if (event !== undefined) {
            $target = $(event.target);
            if ($target.hasClass('js-update-cart-product-global js-add-to-cart')) {
                return;
            }
        }

        if (savedItem || productId !== this.$el.data('pid')) {
            $('.b-add_to_bag', this.$el).spinner().start();
            $('.b-add_to_bag .js-add-to-cart', this.$el).html('');
            $('.b-add_to_bag .js-egiftcard-add-to-cart', this.$el).html('');
            this.$el.trigger('product:beforeAddToCart', this.$addToCartButton);

            if ($('.set-items').length && this.$addToCartButton.hasClass('add-to-cart-global')) {
                setPids = [];

                $('.product-detail', this.$el).each(function () {
                    if (!this.$addToCartButton.hasClass('product-set-detail')) {
                        setPids.push({
                            pid: this.$addToCartButton.find('.product-id').text(),
                            qty: this.$addToCartButton.find('.js-quantity-select').val(),
                            options: this.getOptions(this.$addToCartButton)
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);
            }

            addToCartUrl = this.getAddToCartUrl($target);
            var form;
            if (event !== undefined && event.currentTarget.closest('.b-cart-btn_savelater') !== null) {
                savedItem = false;
                productId = this.$el.data('pid');
                form = {
                    pid: this.$el.data('pid'),
                    pidsObj: pidsObj,
                    childProducts: this.getChildProducts(),
                    quantity: 1
                };
            } else {
                form = {
                    pid: this.$el.data('pid'),
                    pidsObj: pidsObj,
                    childProducts: this.getChildProducts(),
                    quantity: this.getQuantitySelected(this.$addToCartButton) || 1
                };
            }

            if (!$('.bundle-item', this.$el).length) {
                form.options = this.getOptions();
            }

            if ($('input[type="hidden"][name="personalizationName"]').length > 0) form.personalizationName = $('input[name="personalizationName"]').val();
            if ($('input[type="hidden"][name="personalizationNumber"]').length > 0) form.personalizationNumber = $('input[name="personalizationNumber"]').val();
            if ($('input[type="hidden"][name="personalizationSponsors"]').length > 0) form.personalizationSponsors = $('input[name="personalizationSponsors"]').val();

            var eGiftCardFormData = {};
            if (this.$eGiftCardForm.length > 0) {
                var eGiftCardData = this.$eGiftCardForm.serializeArray();
                eGiftCardData.forEach(function (data) {
                    eGiftCardFormData[data.name] = data.value;
                });
                form.eGiftCardData = JSON.stringify(eGiftCardFormData);
            } else if (this.$el.find('.b-cart-btn_savelater').length > 0) {
                eGiftCardFormData.gcRecipientName = this.$el.find('p.t-lineitem_attributes.gcrecipientname').data('gcrecipientname');
                eGiftCardFormData.gcRecipientEmail = this.$el.find('p.t-lineitem_attributes.gcrecipientemail').data('gcrecipientemail');
                eGiftCardFormData.gcFrom = this.$el.find('p.t-lineitem_attributes.gcfrom').data('gcfrom');
                eGiftCardFormData.gcDeliveryDate = this.$el.find('p.t-lineitem_attributes.gcdeliverydate').data('gcdeliverydate');
                eGiftCardFormData.gcMessage = this.$el.find('.gcmessage').data('gcmessage');
                eGiftCardFormData.gcAmount = this.$el.find('.gcamount').data('gcamount');
                form.eGiftCardData = JSON.stringify(eGiftCardFormData);
            }

            this.$addToCartButton.trigger('updateAddToCartFormData', form);
            $('.error-message-text').empty();
            const self = this;
            if (addToCartUrl) {
                var missingSelection = [];
                $.each(requiredSelections, function (index, attributeKey) {
                    var attribute = self.$attrsContainer.find('.b-product_attrs-item[data-attr="' + attributeKey + '"]');
                    if (self.config && self.config.cmp === 'productQuickView') {
                        attribute = self.$attrsContainer.find('.b-product_qvattrs-item[data-attr="' + attributeKey + '"]');
                        attribute.find('.selected.disabled').addClass('m-active');
                    }
                    if (attribute.length) {
                        if (attribute.find('.m-active').length === 0 && attribute.find('.selected').length === 0) {
                            missingSelection.push(attributeKey);
                            attribute.addClass('has-error');
                            if (attribute.find('.invalid-feedback').length) {
                                attribute.find('.invalid-feedback').show();
                            } else {
                                attribute.append('<div class="invalid-feedback">' + attribute.data('error-label') + '</div>');
                            }
                            $('body').trigger('addtobag:error', { errorMsg: attribute.data('error-label') });
                            if (!isVisible(attribute)) {
                                scrollAnimate(attribute);
                            }
                        } else if (attribute.find('.m-active.disabled').length && attribute.find('.selected.disabled').length) {
                            missingSelection.push(attributeKey);
                            var oosMsg = attribute.find('.m-' + attribute.data('attr')).data('error-message-label');
                            if (attribute.find('.selection-error-message').length) {
                                attribute.find('.selection-error-message').show();
                            } else if (self.config && self.config.cmp === 'productQuickView') {
                                attribute.find('.b-product_attribute').append('<div class="selection-error-message">' + oosMsg + '</div>');
                            } else {
                                attribute.append('<div class="selection-error-message">' + oosMsg + '</div>');
                            }
                            $('body').trigger('addtobag:error', {
                                errorMsg: oosMsg
                            });
                        } else {
                            attribute.removeClass('has-error');
                            attribute.find('.invalid-feedback').hide();
                            attribute.find('.selection-error-message').hide();
                        }
                    }
                });
                if (missingSelection.length === 0) {
                    if ($('.js-login').length > 0 && $('.js-add-to-cart').hasClass('js-unlock-access') && !window.memberPriceATC) {
                        $('body').trigger('memberpricing:unlock', {
                            pid: self.$el.data('pid')
                        });
                    } else {
                        if (window.memberPriceATC) {
                            delete window.memberPriceATC;
                        }
                        var dateExceeded = $(this.$el).find('.earlierdate').val();
                        var egcUrl = $(this.$el).find('.js-add-to-cart').data('egc-editurl');
                        var isTrueSet = (dateExceeded === 'true');
                        if ((this.$el.hasClass('egiftcardlineitem') || this.$el.hasClass('cart-savelater-product')) && isTrueSet) {
                            $('.eGCModal').modal('show');
                            $('.eGCModal').find('.js-edit-saveforlater').attr('href', egcUrl);
                            savedItem = true;
                            $.spinner().stop();
                            $('.b-add_to_bag').spinner().stop();
                            $('.js-add-to-cart').text($('.js-add-to-cart').data('addto-bag'));
                        } else {
                            if ($('.ua-early-access').length) {
                                var $uaEarlyAccessBlock = $('.ua-early-access');
                                // Early Access Product
                                if ($uaEarlyAccessBlock.attr('data-is-ea-product') === 'true') {
                                    var addToBagMsg = $('.js-add-to-cart').data('addto-bag');
                                    if ($uaEarlyAccessBlock.attr('data-is-loggedin') === 'false' && $('.js-login').length) {
                                        // Trigger login popup
                                        window.earlyAccessPid = this.$el.data('pid');
                                        $.spinner().stop();
                                        $('.b-add_to_bag', this.$el).spinner().stop();
                                        if (!$('.js-add-to-cart').hasClass('js-unlock-access')) {
                                            $('.js-add-to-cart', this.$el).text(addToBagMsg);
                                        } else {
                                            $('.js-add-to-cart', this.$el).text($('.js-add-to-cart', this.$el).attr('data-memberpricing-text'));
                                        }
                                        $('.b-header_account-link.js-login').trigger('click');
                                        return;
                                    }
                                    if ($uaEarlyAccessBlock.attr('data-is-loggedin') === 'true' && $uaEarlyAccessBlock.attr('data-is-ea-customer') === 'false') {
                                        this.displayEarlyAccessErrorModal($uaEarlyAccessBlock.html());
                                        $.spinner().stop();
                                        $('.b-add_to_bag', this.$el).spinner().stop();
                                        $('.js-add-to-cart', this.$el).text(addToBagMsg);
                                        if (!$('.js-add-to-cart').hasClass('js-unlock-access')) {
                                            $('.js-add-to-cart').removeClass('g-button_primary--black js-unlock-access').addClass('g-button_tertiary');
                                        }
                                        return;
                                    }
                                }
                            }

                            $.ajax({
                                url: addToCartUrl,
                                method: 'POST',
                                data: form,
                                context: this,
                                success: this.onSuccessAddToCart.bind(this),
                                error: function () {
                                    $.spinner().stop();
                                    $('.js-add-to-cart').text($('.js-add-to-cart').data('has-error'));
                                }
                            }).then(this.deselectAmountSwatch());
                            if (event) {
                                event.stopPropagation();
                            }
                        }
                    }
                } else {
                    $.spinner().stop();
                    $('.b-add_to_bag').spinner().stop();
                    if ($('.js-add-to-cart').hasClass('js-unlock-access')) {
                        $('.js-add-to-cart').text($('.js-add-to-cart').data('memberpricing-text'));
                    } else {
                        $('.js-add-to-cart').text($('.js-add-to-cart').data('addto-bag'));
                    }
                }
            }
        }
    }

    // Move this function to APAC to update the E-giftcard add to bag button after prodcut added to cart for APAC site Only.
    onSuccessAddToCart(data) {
        if (data && data.pdpRedirect) {
            // Redirect to Early Access Product PDP
            window.location.href = data.pdpRedirect;
        }
        this.handlePostCartAdd(data);
        var addedToCart = $('.js-add-to-cart, .js-egiftcard-add-to-cart', this.$el).data('added-msg');
        var addAnother = $('.js-add-to-cart, .js-egiftcard-add-to-cart', this.$el).data('add-another');
        var addToBagMsg = $('.js-add-to-cart, .js-egiftcard-add-to-cart').data('addto-bag');
        var hasError = $('.js-add-to-cart, .js-egiftcard-add-to-cart', this.$el).data('has-error') || addToBagMsg;
        var resetQuantity = true;

        if ($('.b-cart-content.cart').length > 0) {
            if (data && data.savedItemAvailabilityError) {
                $('.availability-err', this.$el).removeClass('hide');
                $.spinner().stop();
                $('.b-add_to_bag', this.$el).spinner().stop();
                $('.js-add-to-cart', this.$el).text(addToBagMsg);
                return;
            }

            if (data && data.cart.freeShippingBar) {
                freeShippingBar.methods.updateShippingBar(data.cart.freeShippingBar);
                freeShippingBar.methods.getShippingPromo(data.cart.totals);
            }

            if (!this.$el.hasClass('product-recommendation-quickview')) {
                this.onAddToCartRemoveSaveFromLater(data);
                if (data.cart.hasPreOrder) {
                    $('.js_paypal_button').addClass('hide');
                    $('.js_paypal_button', this.$el).parents('.b-product_actions-inner').addClass('btn-align-mobile');
                } else {
                    $('.js_paypal_button').removeClass('hide');
                    $('.js_paypal_button', this.$el).parents('.b-product_actions-inner').removeClass('btn-align-mobile');
                }
            } else if (data.renderedTemplate) {
                this.$body.trigger('cart:updateCartTotals', [data.cart, this]);
                if ($('.cart').find('.b-cartlineitem').length === 0) {
                    window.location.reload();
                } else {
                    // update cart product cards markup
                    $('.js-cart-items').replaceWith(data.renderedTemplate);
                    // set quantity select values for new product cards
                    Array.from($('.b-cart-content .b-cartlineitem .b-quantity-select')).map(function (select) {
                        $(select).val(parseInt($(select).data().preSelectQty, 10));
                        return select.value;
                    });
                    resetQuantity = false;
                    $('body').trigger('cart:update');
                }
            }
            if ((data.cart.hasGiftCards || data.cart.hasPreOrder) && $('.b-cart_klarna-placement').length > 0) {
                $('.b-cart_klarna-placement').addClass('hide');
            } else {
                $('.b-cart_klarna-placement').removeClass('hide');
            }
        }
        var srContainer = $('.b-cart-content_right .b-cart_shoprunner').closest('div');
        if (data.cart && data.cart.srEligible && srContainer.hasClass('hide')) {
            srContainer.removeClass('hide');
        }
        var analyticsData = {
            analytics: {
                isWishlist: true,
                quantityAdded: this.getQuantitySelected(this.$addToCartButton) || 1,
                isQuickAdd: this.$addToCartButton.closest('.quick-add-dialog, .js-tile-quickAdd_buttonn').length > 0
            }
        };
        $('body').trigger('product:afterAddToCart', $.extend(data, analyticsData));
        this.afterAddToCart();
        $.spinner().stop();
        $('.b-add_to_bag').spinner().stop();
        if (!data.error) {
            this.$el.find('.js-add-to-cart, .js-egiftcard-add-to-cart').addClass('f-added-check').html(addedToCart);
            var self = this;
            setTimeout(function () {
                self.$el.find('.js-add-to-cart.f-added-check, .js-egiftcard-add-to-cart.f-added-check').removeClass('f-added-check').html(addAnother);
            }, 5000);
        } else {
            this.$el.find('.js-add-to-cart, .js-egiftcard-add-to-cart').html(hasError);
        }
        if (resetQuantity) {
            const resetDelay = 3000;

            setTimeout(() => {
                if (this.$el.length && this.$el.is(':visible')) {
                    var $qtySelect = $('.js-quantity-select', this.$el);
                    $qtySelect.find('option:selected').removeAttr('selected');
                    $qtySelect.val($('.js-quantity-select option:first').attr('selected', 'selected').val());
                    if ($('.b-cart-content.cart').length === 0) {
                        $qtySelect.trigger('change');
                    }
                }
                // Revert button text to 'Add To Bag' once product successfully added to bag[E-GiftCard].
                if (this.$eGiftCardForm.length > 0) {
                    $('.js-egiftcard-add-to-cart').removeClass('f-added-check').html(addToBagMsg);
                }
            }, resetDelay);
        }
    }

    afterAddToCart() {
        // Clear eGift Card form
        if (this.$eGiftCardForm.length > 0) {
            this.$eGiftCardForm[0].reset();
            $('.js-egiftcard-add-to-cart').text($('.js-egiftcard-add-to-cart').data('addto-bag'));
        }
    }

    getOptions() {
        var options = this.$el.find('.product-option')
            .map((index, option) => {
                var $elOption = $(option).find('.options-select');
                var urlValue = $elOption.val();
                var selectedValueId = $elOption.find('option[value="' + urlValue + '"]')
                    .data('value-id');
                return {
                    optionId: $(option).data('option-id'),
                    selectedValueId: selectedValueId
                };
            }).toArray();

        return JSON.stringify(options);
    }
}
