/* eslint-disable no-restricted-globals */
/* eslint-disable class-methods-use-this */

'use strict';

import Component from '../forms/FormGeneric';

export default class giftCardAPAC extends Component {
    init() {
        super.init();
        this.checkBalanceForm = $('.js-check-balance-form');
        this.numberField = $('.js-check-balance-form').find('input.f-giftcardinput__balance');
        this.pinField = $('.js-check-balance-form').find('input.f-giftcardinput__pin');
        this.event('focusout', this.checkCardDetails.bind(this), this.numberField);
        this.event('focusout', this.checkCardDetails.bind(this), this.pinField);
        this.event('submit', this.checkGiftBalance.bind(this), this.checkBalanceForm); // For Check balance
    }

    checkGiftBalance(event) { // Function for check balance
        event.preventDefault();
        var giftForm = $('.gift-card__balance-form').serialize();
        var url = this.checkBalanceForm.attr('action');
        var balanceShow = $('.js-check-balance-result-wrapper');
        var errorShow = $('.giftCard-available-balance-errormsg');
        $('.js-check-balance-form-submit').parent('.form-row-button').spinner().start();
        var balanceShowResult = balanceShow.find('.js-result-value');
        var cardExpiryDateResult = balanceShow.find('.js-expiry-value');
        cardExpiryDateResult.text('');
        var expiryShow = balanceShow.find('.js-expiry-result');
        expiryShow.hide();

        $.ajax({
            url: url,
            method: 'POST',
            dataType: 'json',
            data: giftForm,
            success: function (data) {
                if (data.success !== true && data.errorMessage) {
                    errorShow.text(data.errorMessage);
                    errorShow.show();
                    balanceShow.hide();
                    $.spinner().stop();
                } else {
                    if (data.giftCardData && data.giftCardData.currentBalance) {
                        balanceShow.show();
                        balanceShowResult.text(balanceShowResult.data('currency-symbol') + (data.giftCardData.currentBalance));
                        $('.js-check-balance-form-submit').addClass('f-balance-checkmark');
                        setTimeout(function () {
                            $('.js-check-balance-form-submit').removeClass('f-balance-checkmark');
                        }, 3000);
                    }
                    if (data.expiration) {
                        expiryShow.show();
                        cardExpiryDateResult.text(data.expiration);
                    }
                    $.spinner().stop();
                    errorShow.hide();
                }
            }
        });
        return false;
    }

    checkCardDetails(event) {
        event.preventDefault();
        var $this = $(event.currentTarget);
        var inputValue = $this[0].value;
        var regex = $this.attr('pattern');
        var customRegexExp = new RegExp($('[name$="xssRegex"]').val(), '');
        var isFormSubmit = (event.relatedTarget === $('.js-check-balance-form-submit')[0]);
        if (!$this[0].value) {
            $this.parent('.b-input_row').addClass('error-field');
            $this.parent().find('.invalid-feedback').html($this.data('missing-error'));
            return;
        }
        if (customRegexExp.test(inputValue)) {
            $this.addClass('is-invalid');
            $this.parent().find('.invalid-feedback').html($('[name$="xssRegex"]').attr('message'));
            $this.parent('.b-input_row').addClass('error-field');
        } else if ((isNaN(inputValue) || inputValue.match(regex) === null) && !isFormSubmit) {
            if (!(inputValue.length && ($this.parent().find('.invalid-feedback').html() === $this.data('pattern-mismatch')))) {
                $this.addClass('is-invalid');
                $this.parent().find('.invalid-feedback').html($this.data('valid-error'));
                $this.parent('.b-input_row').addClass('error-field');
            }
        } else if (isFormSubmit) {
            // if entered value is invalid and submit button is clicked
            if (!($this.parent().find('.invalid-feedback').html().length)) {
                $this.parent().find('.invalid-feedback').html('');
            }
        } else {
            $this.removeClass('is-invalid');
            $this.parent().find('.invalid-feedback').html('');
            $this.parent('.b-input_row').removeClass('error-field');
        }
        event.stopPropagation();
    }
}
